<script setup lang="ts">
import type { SimpleTableColumn, SimpleTableData } from './types'
import type { SimpleTableVariant } from './style'
import {
  createHeaderSlotProps,
  createItemSlotProps,
  createFooterSlotProps,
  getTemplate,
} from './utils'

const props = defineProps<{
  columns: SimpleTableColumn[]
  data: SimpleTableData
  variant: SimpleTableVariant
  footer: boolean
}>()

const emit = defineEmits<{
  'click:row': [value: any]
}>()

const data = computed(() =>
  Array.isArray(props.data) ? props.data : [props.data],
)
</script>

<template>
  <table :class="variant.table()">
    <tr
      v-for="(column, idx) in columns"
      :key="column.id"
      :class="variant.tbodyTr()"
      :data-column="column.id"
    >
      <th :class="variant.theadTh()">
        <slot
          :name="`header-${column.id}`"
          v-bind="createHeaderSlotProps(column, idx)"
        >
          <slot name="header" v-bind="createHeaderSlotProps(column, idx)">
            <span>{{ column.header }}</span>
          </slot>
        </slot>
      </th>
      <template v-for="(item, idx) in data" :key="idx">
        <td :class="variant.tbodyTd()">
          <slot
            :name="`item-${column.id}`"
            v-bind="createItemSlotProps(column, item, idx)"
          >
            <slot name="item" v-bind="createItemSlotProps(column, item, idx)">
              <span>{{ getTemplate(column, item) ?? '-' }}</span>
            </slot>
          </slot>
        </td>
      </template>
      <th v-if="footer" :class="variant.tfootTh()">
        <slot
          :name="`footer-${column.id}`"
          v-bind="createFooterSlotProps(column, idx)"
        >
          <slot name="footer" v-bind="createFooterSlotProps(column, idx)">
            <span>{{ column.footer }}</span>
          </slot>
        </slot>
      </th>
    </tr>
  </table>
</template>
